<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'OrganizationRegistry' }">← Назад</router-link><br><br>
		<h1 class="heading heading__hr">Создать запись реестра</h1>
		<organization-registry-form :select-options="getFields" mode="edit" @submit="submitForm"/>
	</main>
</template>

<script>
import OrganizationRegistryForm from '@/components/forms/OrganizationRegistryForm.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	components: { OrganizationRegistryForm },
	name: 'CreateOrganization',
	computed: mapGetters(['getFields']),
	methods: {
		...mapActions([ 'fetchFields', 'createRecord']),
		submitForm(data) {
			console.log(data)
			this.createRecord(data);
		},
	},
	created() {
		this.fetchFields()
	}
}
</script>
